@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';


.icon-container{
    width: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    left: 85%;
    top: 90%;
    z-index: 1;
    margin-right: 2rem;
}

#awesomeIcon {
    color: $dark;
}


@media (max-width: 925px){
    .icon-container {
       opacity: 0;
       pointer-events: none;
    }
}

@media (max-height: 600px){
	.icon-container {
        opacity: 0.0;
        pointer-events: none;
	}
}
