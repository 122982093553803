@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';

@mixin shrinkLabel {
    top: -10px;
    font-size: 1rem;
    color: $light;
  }

@mixin form-input{
  background-color: $light;
  color: $primary;
  font-size: $md-txt;
  padding: 10px 10px;
  display: block;
  width: 100%;
  border-radius: .2em;
  border: 1px solid $primary;
  margin: 0rem 0;
}

@mixin form-input-label {
  color: $primary;
  font-size: $sm-txt;
  padding: 0rem 1rem;
  font-weight: $light-weight;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 25px;
  transition: 300ms ease all;
}
  
  .group {
    position: relative;
  
    .form-input {
      @include form-input();
  
      &:focus {
        outline: none;
      }
  
      &:focus ~ .form-input-label {
        @include shrinkLabel();
      }
    }
  
    input[type='password'] {
      letter-spacing: 0.3em;
    }
  
    .form-input-label {
      @include form-input-label();
  
      &.shrink {
        @include shrinkLabel();
      }
    }
  }