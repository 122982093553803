@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';
@import '../form-input/form-input.styles.scss'; //refer to form-input.styles.scss to make changes. Place in mixins folder later.
  
  .group {
    position: relative;
    margin: 2em 0;
  
    .textarea-input {
      @include form-input();
      resize: none;
  
      &:focus {
        outline: none;
      }
  
      &:focus ~ .textarea-input-label {
        @include shrinkLabel();
      }
    }
  
  
    .textarea-input-label {
      @include form-input-label();
  
      &.shrink {
        @include shrinkLabel();
      }
    }
  }