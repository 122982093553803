@import "../../../scss/abstracts/colors";
@import "../../../scss/abstracts/fonts";

.maxImageSize {
  max-width: 90%;
  margin-top: 4rem;
}

.project-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
}
