body {
    font-family:  Montserrat, sans-serif;
    overflow-x: hidden;
}

* {
   margin: 0;
   padding: 0;
    list-style: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.container {
    min-height: 100vh;
    position: relative;
    padding: 0px 36px;
}
  
.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heroImg {
    width: 400px;
    height: auto;
    padding-bottom: 5rem;
  }

.flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


a, h1, h2, h3, h4, h5, h6, p {
  font-style: none;
  text-decoration: none;
  margin: 0;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 2rem;
}

.subsection-title {
  margin-bottom: .5rem;
}

.subsection {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.subsection-paragraph {
  line-height: 1.4rem;
}

.medium-img {
  height: 500px;
  width: auto;
}

.maxW-600{
  min-width: 400px;
  max-width: 700px;
}

.width-600{
  width: 600px;
}

.width-500{
  width: 500px;
}

.width-300{
  width: 300px;
}

.width-200{
  width: 200px;
}

.height-100vh {
  height: 100vh
}

.space {
  justify-content: space-between;
}

.gap-5 {
  gap: 5rem;
}

.pre-wrap {
  white-space: pre-wrap;
}

.image-container {
  min-width: 400px;
  min-height: 400px;
  border-radius: 50%;
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.project-hero {
  margin-top: 4rem;
}

@media (max-width: 500px){
  .project-hero{
    margin-top: 0rem;
  }
}

@media (max-width: 900px){
  .image {
      height: auto;
      width: 100%;
  }
  .mb-sm {
    margin-bottom: 7rem;
  }
}