@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';

.header-links-container {
    display: none;
}

.header-link {
    color: $light;
    font-size: $md-txt;
    font-weight: $med-weight;
    text-decoration: none;
    position: relative;

   &::after{   //SLIDING BOTTOM LINE ANIMATION UNDER LINKS
        content: '';
        display: block;
        height: 3px;
        background-color: $light;
        position: absolute;
        bottom: -1rem;
        width: 0%;
        transition: .2s width ease-in-out;
        }

        &:hover::after{
            width: 100%;
        } 
}

.header-link:hover {
    color: $off-white;
}


.slideIn a{
    transform: translateX(0%);
}

.selected {
   color: $off-white;
   position: relative;
}

.selected::after {
    content: '';
    display: block;
    height: 3px;
    background-color: $light;
    position: absolute;
    bottom: -1rem;
    width: 100%;
}



@media (max-width: 999px){
    .header-links-container a {
        text-decoration: none;
        color: $light;
        font-size: $md-txt;
        font-weight: $med-weight;
        transform: translateY(0%);
        transition: all .2s ease-in;
    }
}
@media (min-width: 1000px) {
    .header-links-container{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }
}