@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';

.footer-background {
    background-color: $primary;
    height: auto;
    padding: 2rem 5%;
    justify-content: space-around;
    position: relative;
    z-index: 2;
}

.footer-link-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0rem 2rem;
    gap: 20%;
    width: 45%;
}

.footer-column-2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.footer-column-margin{
    margin: 0rem 1rem;
}

.column-title {
    color: $dark;
}

.individual-project-links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.link-container {
    align-items: flex-start;
    flex-wrap: wrap;

    & .header-links-container{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        gap: .5rem;
        transform: translateY(0%);
    }

    & .header-link {
        font-size: $sm-txt;
        font-weight: $light-weight;

        &::after{ 
            content: '';
            display: block;
            height: 3px;
            background-color: $light;
            position: absolute;
            bottom: -1rem;
            width: 0%;
            transition: .2s width ease-in-out;
            }
    
            &:hover::after{
                /* Hide the underline from showing in the footer. Change to 100% to show */
                width: 0%;
            } 
    }
}


.link {
    color: white;
    line-height: 1.5rem;
}

.link:hover {
    filter: brightness(80%);
}

.personal-logo-container{
    width: 20%;
}

.personal-logo-container #awesomeIcon {
    color: white;
    margin: .5rem;
}


.personal-logo {
    font-size: 2rem;
    color: white;
    margin: 0rem 1rem;
}

.place-holder{
    margin: 0rem 2rem;
    width: 10%;
}


@media (max-width: 450px){
    .footer-background{
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
    }

    .footer-link-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0rem 2rem;
        gap: 20%;
        width: 45%;
    }

    .personal-logo-container{
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}