@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';


.skills-container{
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-bottom: 5rem;
}

.skills-content-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 3rem;
}

.skills-text-container{
    max-width: 500px;
}

.skills-text-title{
    color: $dark;
    font-size: $lg-txt;
    font-weight: $med-weight;
    margin-bottom: 2rem;
}

.skills-text-paragraph {
    line-height: 1.4rem;
}

.skills-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: auto;
}

@media (max-width: 500px){
    .skills-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}


    

