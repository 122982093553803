@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $primary;
    height: 5rem;
    padding: 0em 1em;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    box-shadow: -19px 20px 38px -3px rgba(3, 8, 63, 0.15);
}


.navbar-logo__line1 {
    font-size: $md-txt;
    font-weight: $heavy-weight;
    color: $light;
}

.navbar-logo__line2 {
    font-size: $sm-txt;
    font-weight: $extra-light;
    color: $light;
}

.navbar-hamburger-container{
    display: block;
}

.navbar-hamburger-container:hover{
    cursor: pointer;
}

.middle-line {
    background-color: $light;
    width: 35px;
    height: 2px;
    margin: 9px 0em;
    transition: transform 0.7s;
}

.top-line{
    background-color: $light;
    width: 35px;
    height: 2px;
    margin: 9px 0em;
    transition: transform 0.7s;
}

.bottom-line {
    background-color: $light;
    width: 35px;
    height: 2px;
    margin: 9px 0em;
    transition: transform 0.7s;
}

.showMenu  {  
    .top-line {
        transform: translateY(11px) rotate(135deg);
        background-color: $light;
    }

    .middle-line {
        transform: translateX(-18px) scaleX(0);
    }

    .bottom-line{
        transform: translateY(-11px) rotate(-135deg);
        background-color: $light;
    }
}

@media (min-width: 1000px) {
    .navbar-hamburger-container {
        display: none;
    }
}

@media (min-width: 1200px) {
    .navbar{
        padding: 0rem 160px;
    }
}
