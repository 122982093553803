@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';

.project-container{
    display: flex;
    align-items: center;
    margin: 2rem 36px;
    position: relative;
    overflow: hidden;
    transition: .2s transform ease-in-out;
}

.project-container:hover{
    transform: scale(1.02);
}

.project-container::after {
    content: '';
    position: absolute;
    width: 100%; 
    height: 100%;
    border-radius: 2em;
    background: $primary;
    opacity: .8;
    transform: translate(0,0);
    transition: .2s opacity ease-in-out;
}

.project-container:hover::after {
    opacity: 0;
}

.project-logo-container {
    width: 23rem;
    height: 23rem;
    border-radius: 2em;  
    background-position: center;
    background-size: cover;
    cursor: pointer;
}



.project-container:hover .project-logo-container{
    cursor: pointer;
}

.project-name, 
.project-container {
    color: $light;
}

.project-name:hover, 
.project-container:hover {
    cursor: pointer;
}

.project-name {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 15%;
    font-size: $xl-txt;
    font-weight: $med-weight;
}

.project-explanation{
    position: absolute;
    z-index: 2;
    top: 85%;
    right: 5%;
}



@media (max-width: 500px){
    .project-logo-container {
        width: 16rem;
        height: 16rem;
    }
    .project-name {
        font-size: $lg-txt;
        font-weight: $med-weight;
    }

}