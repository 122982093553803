/* $primary: #1D86FF; */



/* 
PURPLE SCHEME
$primary:#5E64AB;
$secondary: #7A82DE;

$dark: #34375E;
$light: white;
$off-white: #C1C4E3; 
*/



$primary: #1D86FF;
$secondary: #1D86FF; 

$dark: #121212;
$light:white;
$off-white:rgb(218, 218, 218); 





$skin: #CCAF87;