@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';

.dropdown-container {
    display: block;
    position: absolute;
    top: 5rem;
    right: 0;
    z-index: -1;
    background-color: $primary;
    height: 25rem;
    width: 300px;
    border-radius: 0 0 0 25%;
    box-shadow: -19px 30px 38px -3px rgba(3, 8, 63, 0.15);
    transform: translateX(110%);
    transition: all .5s ease-out;
    
    
    & .header-links-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        height: 80%;
    }
}




@media (min-width: 1000px){
    .dropdown-container{
        display: none;
    }
}

.change {
    transform: translateX(0%);
}
