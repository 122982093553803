@import '../../scss/abstracts/colors';
@import '../../scss/abstracts/fonts';

.skill-container {
    padding: .7rem 1.3rem;
    background-color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skill-title{
    color: $light;
}