@import "../../scss/abstracts/colors";
@import "../../scss/abstracts/fonts";

.aboutMeImg {
  position: absolute;
  top: -10rem;
  right: 4rem;
}

.about-me-container {
  margin-top: 5rem;
  max-width: 600px;
}

.about-me-content {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
}

@media (max-width: 800px) {
  .image-container {
    display: none;
  }
}
