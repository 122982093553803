@import "../../scss/abstracts/colors";
@import "../../scss/abstracts/fonts";

.introduction-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin-top: 5rem;
}

.introduction-container .heroImg {
  min-width: 300px;
  height: auto;
}

.column {
  flex-direction: column;
}

.introduction-heading {
  margin-bottom: 3rem;
}

.introduction-message {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}

.introduction-message__name {
  font-size: $xl-txt;
  font-weight: $heavy-weight;
  color: $dark;
}

.introduction-message__job-title {
  font-size: $xl-txt;
  font-weight: $light-weight;
  color: $dark;
}

.introduction-message__welcome {
  font-size: $lg-txt;
  font-weight: $extra-light;
  line-height: 1.5em;
  margin-bottom: 4rem;
}

.hero-scroll {
  display: none;
}

@media (min-width: 500px) {
  .hero-scroll {
    display: block;
  }
}

@media (max-width: 1000px) {
  .heroImg {
    margin-top: 20%;
    margin-bottom: 30%;
  }

  .introduction-container {
    flex-direction: column-reverse;
    margin-bottom: 10rem;
  }
}

@media (max-height: 700px) {
  .introduction-container .heroImg {
    width: 300px;
  }
}
